

import React from 'react';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog} from '@fortawesome/free-solid-svg-icons';


const Root = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  text-align: center;
  opacity: 0.8;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 3em;
`;


const PlotSpinner = () => (
  <Root>
    <Content>
      <Icon icon={faCog} spin />
    </Content>
  </Root>
)


export default PlotSpinner;
