

import React from 'react';
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';

import * as config from '../config';
import {device, AlphaPaper, Link} from './styles';
import SearchForm from './search-form';
import SearchResults from './search-results';


const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 100vh;
  box-sizing: border-box;
  padding: 1em;
  z-index: 2;
  @media ${device.tablet} {
    width: 80vw;
    padding: 0;
  }
`;

const Header = styled(AlphaPaper)`
  padding: 1em;
  margin-bottom: 1em;
`;

const Title = styled(Typography).attrs(() => ({
  variant: 'h4'
}))`&& {
  font-family: 'Adelle Sans Light';
  font-weight: 500;
  @media ${device.tablet} {
    font-size: 1.2em;
  }
}`;

const HomeLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: ${blue[800]};
    text-decoration: underline;
  }
`;

const Subtitle = styled(Typography).attrs(() => ({
  gutterBottom: true,
}))`&& {
  font-size: 0.9em;
  font-family: 'Adelle Sans Bold';
  color: ${blue[700]};
  margin-left: 0.1em;
}`;

const Flow = styled(Typography)`&& {
  margin-top: 1em;
  font-family: 'Roboto Mono';
  font-size: 12px;
  @media ${device.tablet} {
    display: none;
  }
}`;


const SearchPanel = () => (
  <Root>

    <Header>

      <Title><HomeLink href={config.OSP_HOME_URL}>Open Syllabus</HomeLink></Title>

      <Subtitle>Co-assignment Galaxy</Subtitle>

      <Flow>Citation graph &rarr; <Link href="https://snap.stanford.edu/node2vec/">node2vec</Link> &rarr; <Link href="https://arxiv.org/abs/1802.03426">UMAP</Link></Flow>

      <SearchForm />

    </Header>

    <SearchResults />

  </Root>
)


export default SearchPanel;
