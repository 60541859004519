

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as PIXI from 'pixi.js';
import Viewport from 'pixi-viewport';
import _ from 'lodash';
import KDBush from 'kdbush';
import RBush from 'rbush';
import knn from 'rbush-knn';
import * as d3 from 'd3';
import circlePoint from 'intersects/circle-point';
import {median} from 'mathjs';
import {filter} from 'rxjs/operators';

import * as config from '../config';


const CURSOR_DEBOUNCE_MS = 100;


const Root = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;


function drawPointTexture(radius) {

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = canvas.height = radius * 2;
  context.fillStyle = 'white';

  context.beginPath();
  context.arc(radius, radius, radius, 0, 2 * Math.PI);
  context.fill();

  return PIXI.Texture.from(canvas);

}


function drawBorderPointTexture(radius, lineWidth) {

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = canvas.height = radius * 2;
  context.fillStyle = 'white';
  context.lineWidth = lineWidth;

  context.beginPath();
  context.arc(radius, radius, radius-(lineWidth/2), 0, 2 * Math.PI);
  context.fill();
  context.stroke();

  return PIXI.Texture.from(canvas);

}


// TODO: Split off separate class that wraps the canvas.
class Plot extends React.Component {

  static propTypes = {
    bounds: PropTypes.object,
    labels: PropTypes.array,
    topPoints: PropTypes.array,
    store: PropTypes.object,
    initialExtent: PropTypes.array,
    gRadiusRatioMin: PropTypes.number,
    gRadiusRatioMax: PropTypes.number,
    sRadiusRatioMin: PropTypes.number,
    sRadiusRatioMax: PropTypes.number,
  }

  static defaultProps = {
    gRadiusRatioMin: 4e-4,
    gRadiusRatioMax: 5e-3,
    sRadiusRatioMin: 8e-3,
    sRadiusRatioMax: 3e-2,
  }

  constructor() {

    super();

    this.containerRef = React.createRef();

    // TODO: Wrap in some kind of "state" object?

    // Is the viewport currently being dragged?
    this.isMoving = false;

    // Is the cursor on the viewport?
    this.isHovering = true;

    // To check if a mouseup is a selection click or a drag release.
    this.lastPointerDown = Date.now();
    this.lastMoveStart = Date.now();

  }

  render() {
    return <Root ref={this.containerRef} />
  }

  componentDidMount() {

    this._initApp();
    this._initTextures();
    this._initScales();
    this._initViewportExtent();
    this._initPoints();
    this._initLabels();
    this._initHighlight();
    this._initSelect();
    this._initInteractionListeners();
    this._initStoreListeners();

  }

  _initApp() {

    this.app = new PIXI.Application({
      resizeTo: this.containerRef.current,
      resolution: window.devicePixelRatio,
      autoDensity: true,
      antialias: true,
      backgroundColor: 0xffffff,
      autoStart: false,
    });

    this.containerRef.current.appendChild(this.app.view);

    this.zoom = new Viewport({
      screenWidth: this.app.screen.width,
      screenHeight: this.app.screen.height,
      divWheel: this.containerRef.current,
    });

    this.zoom.drag().pinch().wheel();
    this.zoom.interactiveChildren = false;

    this.app.stage.addChild(this.zoom);

    window.addEventListener('resize', this.onResize.bind(this));

  }

  _initTextures() {

    // TODO: Use PIXI graphics?
    // TODO: Parametrize.
    this.pointTexture = drawPointTexture(100);
    this.hlPointTexture = drawBorderPointTexture(100, 25);

  }

  _initScales() {

    const {x_min, x_max, y_min, y_max, r_min, r_max} = this.props.bounds;

    // Map largest data dim -> smallest canvas dim.
    const [dataMin, dataMax] =
      (x_max - x_min) > (y_max - y_min) ?
      [x_min, x_max] : [y_min, y_max];

    const canvasDim = Math.min(
      this.app.screen.width,
      this.app.screen.height,
    );

    const baseScale = d3.scaleLinear().domain([dataMin, dataMax]);

    // Flip Y coordinates, to keep +y -> up.
    this.xScale = baseScale.copy().range([0, canvasDim]);
    this.yScale = baseScale.copy().range([canvasDim, 0]);

    const rDomain = [r_min, r_max];

    this.gRadiusScale = d3
      .scaleSqrt()
      .domain(rDomain)
      .range([
        canvasDim * this.props.gRadiusRatioMin,
        canvasDim * this.props.gRadiusRatioMax,
      ]);

    this.sRadiusScale = d3
      .scaleSqrt()
      .domain(rDomain)
      .range([
        this.props.sRadiusRatioMin,
        this.props.sRadiusRatioMax,
      ]);

  }

  _initViewportExtent() {

    // TODO: Use zoom.ensureVisible?

    const [tlx, tly, brx, bry] = this.props.initialExtent || [
      this.props.bounds.x_min,
      this.props.bounds.y_max,
      this.props.bounds.x_max,
      this.props.bounds.y_min,
    ]

    const cx = this.xScale(median(tlx, brx));
    const cy = this.yScale(median(tly, bry));

    const worldW = this.xScale(brx) - this.xScale(tlx);
    // Because y+ -> bottom, on screen.
    const worldH = this.yScale(bry) - this.yScale(tly);

    const worldRatio = worldW / worldH;
    const screenRatio = this.app.screen.width / this.app.screen.height;

    this.zoom.moveCenter(cx, cy);

    if (screenRatio > worldRatio) {
      this.zoom.fitHeight(worldH, true);
    } else {
      this.zoom.fitWidth(worldW, true);
    }

  }

  _initPoints() {

    this.top = new PIXI.Container();
    this.bottom = new PIXI.Container();

    this.idToSprite = new Map();

    // Seed with top points.
    for (let work of this.props.topPoints) {
      this.addWork(work, this.top)
    }

    this.zoom.addChild(this.bottom, this.top);

    // Build BB index for top points.
    this.topTree = new KDBush(this.props.topPoints,
      p => p.point[0], p => p.point[1]);

  }

  _initLabels() {

    // Get the max texture dimension.
    const gl = this.app.renderer.gl;
    const maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

    const labels = new PIXI.Container();

    for (let {name, point, width, alpha} of this.props.labels) {

      const style = new PIXI.TextStyle({
        fontSize: 100,
        fill: 'black',
        fontWeight: 'bold',
        align: 'center',
      });

      const measure = () => (
        PIXI.TextMetrics.measureText(name, style).width *
        window.devicePixelRatio
      )

      // TODO: Calculate directly, instead of probing?
      // If texture is too large, notch down font size until we're in bounds.
      while (measure() > maxSize) {
        style.fontSize -= 10;
      }

      const label = new PIXI.Text(name, style);

      const [dx, dy] = point.split(',').map(parseFloat);
      const wx = this.xScale(dx);
      const wy = this.yScale(dy);

      const worldWidth = this.xScale(width) - this.xScale(0);
      const scale = worldWidth / label.width;

      label.position.set(wx, wy);
      label.width = worldWidth;
      label.height *= scale;
      label.alpha = alpha || 0.4;

      labels.addChild(label);

    }

    this.zoom.addChild(labels);

  }

  _initHighlight() {

    this.highlight = new PIXI.Container();
    this.highlight.visible = false;

    const point = new PIXI.Sprite(this.hlPointTexture);

    point.tint = 0xff0000;
    point.alpha = 0.7;

    this.highlight.addChild(point);
    this.highlightPoint = point;

    this.app.stage.addChild(this.highlight);

  }

  _initSelect() {

    // TODO: selection
    this.select = new PIXI.Container();
    this.select.visible = false;

    const point = new PIXI.Sprite(this.hlPointTexture);

    point.tint = 0xff0000;
    point.alpha = 0.9;

    this.select.addChild(point);
    this.selectPoint = point;

    this.app.stage.addChild(this.select);

  }

  _initInteractionListeners() {

    // Build initial visible viewport, before adding cursor events.
    this.updateExtent();

    // Leading debounce for start.
    this.onMoveStartDebounced = _.debounce(
      this.onMoveStart.bind(this),
      CURSOR_DEBOUNCE_MS,
      {leading: true, trailing: false},
    );

    // Trailing debounce for end.
    this.onMoveEndDebounced = _.debounce(
      this.onMoveEnd.bind(this),
      CURSOR_DEBOUNCE_MS,
      {leading: false, trailing: true},
    );

    this.zoom
      .on('moved', this.onMove.bind(this))
      .on('mousemove', this.onMouseMove.bind(this))
      .on('pointerdown', this.onPointerDown.bind(this))
      .on('pointerup', this.onPointerUp.bind(this))
      .on('pointerover', this.onPointerOver.bind(this))
      .on('pointerout', this.onPointerOut.bind(this));

  }

  _initStoreListeners() {

    this.props.store.HIGHLIGHTED_WORK
      .subscribe(this.renderHighlight.bind(this));

    this.props.store.SELECTED_WORK
      .subscribe(this.renderSelect.bind(this));

    // Ignore empty startup value.
    // TODO: Should this be a Subject, not BehaviorSubject?
    this.props.store.BOTTOM_POINTS
      .pipe(filter(Boolean))
      .subscribe(this.setBottomPoints.bind(this));

    this.props.store.ZOOM_PLOT_TO_SELECTED
      .subscribe(this.zoomToSelected.bind(this));

    // TODO: Store-level effects?

    this.props.store.ROUTER_WORK
      .pipe(filter(Boolean))
      .subscribe(work => this.props.store.selectWorkAndZoom(work));

    this.props.store.ROUTER_EXTENT
      .pipe(filter(Boolean))
      .subscribe(this.snapToExtent.bind(this));

  }

  requestFrame() {
    window.requestAnimationFrame(() => this.app.render());
  }

  addWork(work, container) {

    // Don't double-add a work. Eg, if a point has been selected, and then is
    // also prework in the next bottom set, don't re-add it.
    if (this.idToSprite.has(work.work_id)) return;

    const x = this.xScale(work.point[0]);
    const y = this.yScale(work.point[1]);

    const gRadius = this.gRadiusScale(work.doc_count);
    const sRadius = this.sRadiusScale(work.doc_count);

    const sprite = new PIXI.Sprite(this.pointTexture);

    sprite.tint = PIXI.utils.string2hex(work.color);
    sprite.width = sprite.height = gRadius * 2;
    sprite.alpha = 0.6;

    sprite.work = work;
    sprite.gRadius = gRadius;
    sprite.sRadius = sRadius;
    sprite.x0 = x;
    sprite.y0 = y;

    sprite.position.set(sprite.x0 - gRadius, sprite.y0 - gRadius);

    container.addChild(sprite);

    this.idToSprite.set(work.work_id, sprite);

    return sprite;

  }

  updateExtent() {

    // Get world bb.
    const minX = this.xScale.invert(this.zoom.left);
    const minY = this.yScale.invert(this.zoom.bottom);
    const maxX = this.xScale.invert(this.zoom.right);
    const maxY = this.yScale.invert(this.zoom.top);

    // Register current plot extent.
    this.props.store.setPlotExtent({minX, maxX, minY, maxY});

    // Query visible points.
    const topIdxs = this.topTree.range(minX, minY, maxX, maxY);
    this.visibleTopSprites = topIdxs.map(idx => this.top.children[idx]);

    // If <N visible, request more.
    if (this.visibleTopSprites.length < config.MIN_TOP_VISIBLE) {
      this.props.store.loadBottomPoints();
    }

    // Otherwise, clear previous bottom set.
    else this.clearBottom();

    // Scale + index the new set of visible points.
    this.indexExtent();

  }

  indexExtent() {

    // Top +  bottom.
    const visibleSprites = [
      ...this.visibleTopSprites,
      ...this.bottom.children,
    ];

    // Apply semantic zoom.

    const screenWidth = this.zoom.right - this.zoom.left;

    for (let sprite of visibleSprites) {

      const radius = (sprite.gRadius / screenWidth > sprite.sRadius) ?
        sprite.sRadius * screenWidth : sprite.gRadius;

      // TODO: Only update if changed?
      sprite.position.set(sprite.x0 - radius, sprite.y0 - radius);
      sprite.width = sprite.height = radius * 2;

    }

    // Build cursor rtree.

    const treePoints = visibleSprites
      .map(sprite => ({
        work: sprite.work,
        radius: sprite.width / 2,
        minX: sprite.x0,
        maxX: sprite.x0,
        minY: sprite.y0,
        maxY: sprite.y0,
      }));

    this.extentTree = new RBush();
    this.extentTree.load(treePoints);

    // Sync with new semantic radii.
    this.syncSelect();
    this.syncHighlight()

    // Ensure all points rendered.
    this.requestFrame();

    this.props.store.setNumVisible({
      top: this.visibleTopSprites.length,
      bottom: this.bottom.children.length,
    });

  }

  _queryScreen(evt) {

    const {x: worldX, y: worldY} = this.zoom.toWorld(evt.data.global);

    // Query for KNN from cursor.
    const nn = knn(this.extentTree, worldX, worldY, 10);

    // Check for circle overlap.
    const hits = nn.filter(p =>
      circlePoint(p.minX, p.minY, p.radius, worldX, worldY));

    return hits.length ?
      _.sortBy(hits, p => -p.radius)[0].work :
      null;

  }

  queryHighlighted(evt) {

    const current = this.props.store.HIGHLIGHTED_WORK.getValue();
    const hit = this._queryScreen(evt);

    // If rolling onto point, or moving from A -> B.
    if (hit && (!current || current.work_id !== hit.work_id)) {
      this.props.store.highlightWork(hit);
      this.props.store.showHighlightTip();
    }

    // Rolling off point.
    else if (!hit && current) {
      this.props.store.clearHighlightedWork();
    }

  }

  querySelected(evt) {

    // Don't select on release after a viewport drag.
    if (this.lastPointerDown < this.lastMoveStart) return;

    const hit = this._queryScreen(evt);

    // Select clicked work.
    if (hit) this.props.store.selectWork(hit);

    // Otherwise, clickoff.
    else this.props.store.clearSelectedWork();

  }

  logClick(evt) {
    const {x: worldX, y: worldY} = this.zoom.toWorld(evt.data.global);
    const dataX = this.xScale.invert(worldX);
    const dataY = this.yScale.invert(worldY);
    console.log(`${dataX.toFixed(3)},${dataY.toFixed(3)}`);
  }

  // ** Event handlers **

  onResize() {
    this.app.resize();
    this.zoom.resize(this.app.screen.width, this.app.screen.height);
    this.requestFrame();
    // TODO: Update extent?
  }

  onMove() {
    this.onMoveStartDebounced();
    this.onMoveEndDebounced();
    this.syncSelect();
    this.syncHighlight();
  }

  onMoveStart() {
    this.isMoving = true;
    this.lastMoveStart = Date.now();
    this.app.start();
    this.props.store.notifyPlotMoveStart();
  }

  onMoveEnd() {
    this.isMoving = false;
    this.app.stop();
    this.updateExtent();
  }

  onMouseMove(evt) {

    // Don't query if viewport is moving or cursor is on overlay.
    // NOTE: Key perf optimization. Otherwise KNN runs each drag tick.
    if (!this.isMoving && this.isHovering) {
      this.queryHighlighted(evt)
    }

  }

  onPointerDown() {
    this.lastPointerDown = Date.now();
  }

  onPointerUp(evt) {
    this.querySelected(evt);
    this.logClick(evt);
    this.props.store.notifyPlotClick();
  }

  onPointerOver() {
    this.isHovering = true;
  }

  onPointerOut() {
    this.isHovering = false;
    this.props.store.clearHighlightedWork();
  }

  getOrAddSprite(work) {

    // Try to get existing sprite.
    if (this.idToSprite.has(work.work_id)) {
      return this.idToSprite.get(work.work_id)
    }

    // If missing, add to bottom.
    else {
      return this.addWork(work, this.bottom);
    }

  }

  syncHighlight() {

    // TODO: Is this slow?
    const work = this.props.store.HIGHLIGHTED_WORK.getValue();

    if (work) {

      const point = this.getOrAddSprite(work);
      const {x, y} = this.zoom.toScreen(point.x0, point.y0);

      const worldRadius = point.width / 2;

      const screenRadius = this.zoom.screenWidth * worldRadius /
        this.zoom.worldScreenWidth;

      const radius = Math.max(screenRadius, 10);

      // Sync size.
      this.highlightPoint.width = this.highlightPoint.height =
        radius * 2;

      // Sync position.
      this.highlightPoint.position.set(x-radius, y-radius);

      this.app.view.style.cursor = 'pointer';
      this.highlight.visible = true;

    }

    else {
      this.app.view.style.cursor = 'default';
      this.highlight.visible = false;
    }

  }

  syncSelect() {

    const work = this.props.store.SELECTED_WORK.getValue();

    if (work) {

      const point = this.getOrAddSprite(work);
      const {x, y} = this.zoom.toScreen(point.x0, point.y0);

      const worldRadius = point.width / 2;

      const screenRadius = this.zoom.screenWidth * worldRadius /
        this.zoom.worldScreenWidth;

      const radius = Math.max(screenRadius, 15);

      // Sync size.
      this.selectPoint.width = this.selectPoint.height = radius * 2;

      // Sync position.
      this.selectPoint.position.set(x-radius, y-radius);

      this.select.visible = true;

    }

    else {
      this.select.visible = false;
    }

  }

  renderHighlight() {
    this.syncHighlight();
    this.requestFrame();
  }

  renderSelect() {
    this.syncSelect();
    this.requestFrame();
  }

  clearBottom() {

    const keepIds = [
      this.props.store.getSelectedWorkId(),
      this.props.store.getHighlightedWorkId(),
    ];

    const toRemove = [];
    for (let sprite of this.bottom.children) {

      // Don't remove selected / highlighted sprites.
      if (keepIds.includes(sprite.work.work_id)) {
        continue;
      }

      this.idToSprite.delete(sprite.work.work_id);
      toRemove.push(sprite);

    }

    // TODO: Destroy?
    // https://github.com/pixijs/pixi.js/wiki/v4-Tips,-Tricks,-and-Pitfalls#destroying-objects
    this.bottom.removeChild(...toRemove);

  }

  setBottomPoints(works) {

    this.clearBottom();

    for (let work of works) {
      this.addWork(work, this.bottom);
    }

    this.indexExtent();

  }

  zoomTo(x, y, width, time=500) {

    this.zoom.snap(x, y, {
      time: time,
      removeOnInterrupt: true,
      removeOnComplete: true,
    });

    this.zoom.snapZoom({
      width: width,
      time: time,
      removeOnInterrupt: true,
      removeOnComplete: true,
    });

  }

  zoomToSelected() {

    // TODO: Handle null work? Pass work?
    const work = this.props.store.SELECTED_WORK.getValue();

    const sprite = this.idToSprite.get(work.work_id);

    this.zoomTo(sprite.x0, sprite.y0, 300);

  }

  snapToExtent([tlx, tly, brx, bry]) {

    const cx = this.xScale(median(tlx, brx));
    const cy = this.yScale(median(tly, bry));

    const width = this.xScale(brx) - this.xScale(tlx);

    this.zoom.fitWidth(width);
    this.zoom.moveCenter(cx, cy);
    this.updateExtent();

  }

}


export default Plot;
