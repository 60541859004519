

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components'


const Root = styled.div`
  padding: 1em;
`;


const SearchResultsSpinner = () => (
  <Root>
    <CircularProgress />
  </Root>
)


export default SearchResultsSpinner;
