


import React from 'react';


const HTMLSpan = ({html}) => (
  <span dangerouslySetInnerHTML={{__html: html}} />
)


export default HTMLSpan;
