

import React, {useRef, useEffect} from 'react';
import styled from 'styled-components'

import TextField from '@material-ui/core/TextField';

import store from '../store';


const Root = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)`
  margin: 0;
  input::-webkit-search-cancel-button {
    position: relative;
    right: 5px;
    -webkit-appearance: none;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: lightgray;
    cursor: pointer;
    &:hover {
      background: gray;
    }
  }
`;


const SearchForm = () => {

  const inputRef = useRef(null);

  const onFocus = () => store.showSearchResults();

  // Blur on submit, but block HTML submit.
  const onSubmit = (e) => {
    e.preventDefault();
    inputRef.current.blur();
  }

  // Query for results.
  const onChange = (e) => {
    store.setSearchQuery(e.target.value);
  }

  // Blur on plot zoom / drag.
  // TODO: Move to store? Need access to ref..
  useEffect(() => {

    const sub = store.PLOT_MOVE_START
      .subscribe(() => inputRef.current.blur());

    return () => sub.unsubscribe();

  });

  return (
    <Root onSubmit={onSubmit}>
      <StyledTextField
        label="Search the catalog"
        type="search"
        margin="dense"
        inputRef={inputRef}
        onFocus={onFocus}
        onChange={onChange}
      />
    </Root>
  )

}


export default SearchForm;
