

import {useState, useEffect} from 'react';
import * as config from './config';


export function useObservable(observable, initialValue=null) {

  // TODO: If BehaviorSubject, use getValue()?
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const subscription = observable.subscribe(setValue);
    return () => subscription.unsubscribe();
  }, []);

  return value;

}


export function useWindowMousePosition() {

  const [xy, setXY] = useState({x: null, y: null});

  function onMove(e) {
    setXY({x: e.pageX, y: e.pageY});
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMove);
    return () => window.removeEventListener('mousemove', onMove);
  }, []);

  return xy;

}


export function buildExtentURL(tlbr) {
  const args = tlbr.map(c => c.toFixed(3)).join('/');
  return `${window.location.origin}/#!extent/${args}`;
}


export function buildWorkURL(id) {
  return `${window.location.origin}/#!work/${id}`;
}


export function buildExplorerWorkURL(id) {
  return `${config.OSP_HOME_URL}/result/title?id=${id}`;
}
