

import React from 'react';
import styled from 'styled-components';

import {useObservable, buildExtentURL} from '../utils';
import store from '../store';
import CopyLink from './copy-link';


const Root = styled.div`
  position: fixed;
  right: 1em;
  bottom: 1em;
  font-family: 'Roboto Mono';
  color: gray;
  font-size: 11px;
  text-align: right;
  user-select: none;
`;

const Value = styled.span`
  color: black;
  font-weight: 500;
`;


const Extent = () => {

  const tlbr = useObservable(store.PLOT_EXTENT);

  if (!tlbr) return null;

  const [tlx, tly, brx, bry] = tlbr
    .map(c => <Value>{c.toFixed(3)}</Value>)

  const url = buildExtentURL(tlbr);

  return <Root>
    <CopyLink url={url}>Copy link</CopyLink>
    <div>{tlx}/{tly}/{brx}/{bry}</div>
  </Root>

}


// TODO: Re-add num points?
export default Extent;
