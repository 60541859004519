

import App from '../atlas/app';


/*
fouc on firefox?
disable page zoom
load test, EB scaling settings

maybe
  byline at bottom
  align ranks
  label font sizes?
  explorer font
*/


export default App;
