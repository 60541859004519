

import React from 'react';
import styled, {css} from 'styled-components';

import blue from '@material-ui/core/colors/blue';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

import store from '../store';
import {useObservable} from '../utils';


const Root = styled.div`
  position: fixed;
  right: 1em;
  top: 1em;
`;

const iconStyles = css`
  opacity: 0.7;
  margin-left: 0.2em;
  &:hover {
    opacity: 1;
    color: ${blue[800]};
    cursor: pointer;
  }
`;

const InfoIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faQuestionCircle
}))`
  ${iconStyles}
  font-size: 2.2em;
`;


const InfoToggle = () => {

  const work = useObservable(store.SELECTED_WORK);

  const onClick = () => store.showInfo();

  // TODO: Don't hide on mobile.
  return work ? null : (
    <Root>
      <InfoIcon onClick={onClick} />
    </Root>
  );

}


export default InfoToggle;
