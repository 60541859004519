

import styled from 'styled-components';

import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';


const size = {
  tablet: '768px',
};


export const device = {
  tablet: `(max-width: ${size.tablet})`,
};


export const AlphaPaper = styled.div`
  box-sizing: border-box;
  background: rgba(250,250,250,0.95);
  border-radius: 3px;
  border: 1px solid #e4e4e4;
`;


export const Link = styled.a.attrs(() => ({
  target: '_blank',
}))`
  color: ${blue[800]};
`;


export const Code = styled.span`
  font-family: 'Roboto Mono';
  color: ${red[800]};
  background: whitesmoke;
  padding: 2px;
  font-size: 0.9em;
`
