

import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';

import router from './router';
import store from './store';
import {useObservable} from './utils';

import PlotSpinner from './components/plot-spinner';
import Plot from './components/plot';
import SearchPanel from './components/search-panel';
import HighlightTip from './components/highlight-tip';
import SelectedWork from './components/selected-work';
import Stats from './components/stats';
import BBSpinner from './components/bb-spinner';
import InfoToggle from './components/info-toggle';
import Info from './components/info';

import bounds from './bounds.json';
import labels from './fields.yml';


const App = () => {

  const topPoints = useObservable(store.TOP_POINTS, null);

  // Show spinner while loading top, then render plot.
  const plot = !topPoints ? <PlotSpinner /> :
    <Plot
      bounds={bounds}
      labels={labels}
      topPoints={topPoints}
      store={store}
      initialExtent={[-11.400,8.134,10.152,-8.381]}
    />

  return <>

    <Helmet>
      <title>OSP Galaxy</title>
    </Helmet>

    {plot}
    <SearchPanel />
    <HighlightTip />
    <SelectedWork />
    <Stats />
    <BBSpinner />
    <Info />
    <InfoToggle />

  </>

}


export default () => {
  useEffect(() => router.start({hashbang: true}));
  return <App />
}
