

import {BehaviorSubject, Subject, merge} from 'rxjs';
import {debounceTime, map, partition} from 'rxjs/operators';

import * as config from './config';
import {loadTop, queryBB, queryFT, loadWork} from './api';


class Store {

  constructor() {

    // state
    this.ROUTER_WORK = new BehaviorSubject(null);
    this.ROUTER_EXTENT = new BehaviorSubject(null);
    this.TOP_POINTS = new BehaviorSubject(null);
    this.PLOT_EXTENT = new BehaviorSubject(null);
    this.HIGHLIGHTED_WORK = new BehaviorSubject(null);
    this.HIGHLIGHT_TIP_VISIBLE = new BehaviorSubject(false);
    this.SELECTED_WORK = new BehaviorSubject(null);
    this.NUM_POINTS_VISIBLE = new BehaviorSubject(null);
    this.BB_LOADING = new BehaviorSubject(false);
    this.BOTTOM_POINTS = new BehaviorSubject(null);
    this.INFO_VISIBLE = new BehaviorSubject(false);
    this.FT_LOADING = new BehaviorSubject(false);
    this.SEARCH_QUERY = new BehaviorSubject('');
    this.SEARCH_RESULTS_VISIBLE = new BehaviorSubject(false);
    this.SEARCH_RESULTS = new BehaviorSubject(null);

    // events
    this.PLOT_MOVE_START = new Subject();
    this.PLOT_CLICK = new Subject();

    // dispatches
    this.ZOOM_PLOT_TO_SELECTED = new Subject();

    this.bindSearchEffects();
    this.loadTopPoints();

  }

  bindSearchEffects() {

    const [query, empty] = this.SEARCH_QUERY
      .pipe(debounceTime(300))
      .pipe(map(q => q.trim()))
      .pipe(partition(q => q));

    query.subscribe(this.loadSearchResults.bind(this));
    empty.subscribe(this.clearSearchResults.bind(this));

    merge(this.PLOT_CLICK, this.PLOT_MOVE_START)
      .subscribe(this.hideSearchResults.bind(this));

  }

  async loadTopPoints() {
    const res = await loadTop();
    this.TOP_POINTS.next(res.data);
  }

  async loadBottomPoints() {

    // TODO: Pass extent, to avoid call order dependency with setPlotExtent?
    const tlbr = this.PLOT_EXTENT.getValue();

    this.BB_LOADING.next(true);
    const res = await queryBB(tlbr, config.BOTTOM_QUERY_SIZE);

    if (res) {
      this.BOTTOM_POINTS.next(res.data);
      this.BB_LOADING.next(false);
    }

  }

  async loadSearchResults(query) {

    this.FT_LOADING.next(true);
    const res = await queryFT(query);

    if (res) {
      this.SEARCH_RESULTS.next(res.data);
      this.FT_LOADING.next(false);
    }

  }

  // NOTE: Could try to pull from top points, if already loaded.
  async loadRouterWork(id) {
    const res = await loadWork(id);
    this.ROUTER_WORK.next(res.data);
  }

  setRouterExtent(tlbr) {
    this.ROUTER_EXTENT.next(tlbr);
  }

  highlightWork(work) {
    this.HIGHLIGHTED_WORK.next(work);
  }

  clearHighlightedWork() {
    this.HIGHLIGHTED_WORK.next(null);
    this.clearHighlightTip();
  }

  showHighlightTip() {
    this.HIGHLIGHT_TIP_VISIBLE.next(true);
  }

  clearHighlightTip() {
    this.HIGHLIGHT_TIP_VISIBLE.next(false);
  }

  selectWork(work) {
    this.SELECTED_WORK.next(work);
    this.clearHighlightedWork();
  }

  selectWorkAndZoom(work) {
    this.selectWork(work);
    this.zoomPlotToSelected();
  }

  clearSelectedWork() {
    this.SELECTED_WORK.next(null);
  }

  setPlotExtent({minX, maxX, minY, maxY}) {
    this.PLOT_EXTENT.next([minX, maxY, maxX, minY]);
  }

  setNumVisible(counts) {
    this.NUM_POINTS_VISIBLE.next(counts);
  }

  getHighlightedWorkId() {
    const work = this.HIGHLIGHTED_WORK.getValue();
    return work ? work.work_id : null;
  }

  getSelectedWorkId() {
    const work = this.SELECTED_WORK.getValue();
    return work ? work.work_id : null;
  }

  notifyPlotMoveStart() {
    this.PLOT_MOVE_START.next();
    this.clearHighlightTip();
  }

  notifyPlotClick() {
    this.PLOT_CLICK.next();
  }

  showInfo() {
    this.INFO_VISIBLE.next(true);
  }

  hideInfo() {
    this.INFO_VISIBLE.next(false);
  }

  showSearchResults() {
    this.SEARCH_RESULTS_VISIBLE.next(true);
  }

  hideSearchResults() {
    this.SEARCH_RESULTS_VISIBLE.next(false);
  }

  setSearchQuery(query) {
    this.SEARCH_QUERY.next(query);
  }

  clearSearchResults() {
    this.SEARCH_RESULTS.next(null);
  }

  zoomPlotToSelected() {
    this.ZOOM_PLOT_TO_SELECTED.next();
  }

  snapPlotToExtent(tlbr) {
    this.SNAP_PLOT_TO_EXTENT.next(tlbr);
  }

}


export default new Store();
