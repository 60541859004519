

import React from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faTimes, faExternalLinkSquareAlt}
  from '@fortawesome/free-solid-svg-icons';

import store from '../store';
import {useObservable, buildWorkURL, buildExplorerWorkURL} from '../utils';
import {AlphaPaper, device} from './styles';
import HTMLSpan from './html-span';
import CopyLink from './copy-link';


const Root = styled(AlphaPaper)`
  position: fixed;
  right: 1em;
  top: 1em;
  padding: 1em;
  width: 280px;
  z-index: 1;
  em {
    font-style: normal;
  }
  @media ${device.tablet} {
    left: 1em;
    bottom: 1em;
    top: auto;
  }
`;

const Title = styled(Typography).attrs(() => ({
  variant: 'h5'
}))`&& {
  font-weight: bold;
  font-size: 1.3em;
}`;

const Author = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`&& {
  font-weight: 400;
  font-size: 1em;
  color: ${grey[600]};
}`;

const Metrics = styled.div`
  margin-top: 0.5em;
`;

const MetricLine = styled(Typography).attrs(() => ({
  variant: 'body2',
  display: 'block',
}))`&& {
  line-height: 1.5;
}`;

const MetricValue = styled.span`
  font-weight: bold;
`;

const OutButton = styled(Button).attrs(() => ({
  color: 'primary',
  variant: 'outlined',
}))`&& {
  margin-top: 1em;
}`;

const OutIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faExternalLinkSquareAlt,
}))`
  margin-left: 0.5em;
`;

const CloseIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTimes,
}))`
  float: right;
  margin-left: 1em;
  font-size: 1.5em;
  &:hover {
    color: ${blue[800]};
    cursor: pointer;
  }
`;

const FieldBadge = styled.div`
  display: inline-block;
  color: white;
  font-family: Roboto;
  font-size: 0.8em;
  padding: 2px 4px;
  margin-bottom: 0.5em;
  border-radius: 3px;
  opacity: 0.9;
`;

const CopyLinkText = styled(Typography).attrs(() => ({
  variant: 'caption',
  display: 'block',
}))`&& {
  margin-top: 1em;
}`;


const Field = ({name, color}) => (
  <FieldBadge style={{background: color}}>
    {name || 'Multiple fields'}
  </FieldBadge>
)


const SelectedWork = () => {

  const work = useObservable(store.SELECTED_WORK);

  const onClose = () => {
    store.clearSelectedWork();
  }

  // Hide if no selected work.
  if (!work) return null;

  const count = work.doc_count.toLocaleString();
  const url = buildWorkURL(work.work_id);
  const explorerURL = buildExplorerWorkURL(work.work_id);

  return (
    <Root>

      <CloseIcon onClick={onClose} />
      <Field name={work.primary_field} color={work.color} />
      <Title><HTMLSpan html={work.title} /></Title>
      <Author><HTMLSpan html={work.authors.join(', ')} /></Author>

      <Metrics>
        <MetricLine>
          Assigned on <MetricValue>{count}</MetricValue> syllabi
        </MetricLine>
      </Metrics>

      <OutButton href={explorerURL} target="explorer">
        View profile<OutIcon />
      </OutButton>

      <CopyLinkText>
        <CopyLink url={url}>Copy link to this work</CopyLink>
      </CopyLinkText>

    </Root>
  )

}


export default SelectedWork;
