

import React from 'react';
import styled from 'styled-components';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import red from '@material-ui/core/colors/red';

import store from '../store';
import HTMLSpan from './html-span';


const StyledListItem = styled(ListItem)`&& {
  transition: none;
}`;

const StyledListItemText = styled(ListItemText)`&& {
  em {
    font-style: normal;
    color: ${red[600]};
    text-decoration: underline;
  }
}`

const Metrics = styled.div`
  color: gray;
  font-size: 0.9em;
`;

const Rank = styled.span`
  font-weight: bold;
`;

const Title = styled.span`
  font-weight: bold;
`;


const SearchResultsRow = ({work}) => {

  const onMouseOver = () => store.highlightWork(work);
  const onClick = () => store.selectWorkAndZoom(work);

  const metrics = <Metrics>
    <Rank>{work.doc_count.toLocaleString()}</Rank> syllabi
  </Metrics>

  const title = <Title><HTMLSpan html={work.title} /></Title>

  const author = <HTMLSpan html={work.authors.join(', ')} />

  return (
    <StyledListItem
      button
      onMouseOver={onMouseOver}
      onClick={onClick}>
      <StyledListItemText>
        {metrics}
        {title}, {author}
      </StyledListItemText>
    </StyledListItem>
  )

}


export default SearchResultsRow;
