

import page from 'page';

import store from './store';


// Work hard-link.
page('work/:id', ctx => {
  const id = parseInt(ctx.params.id)
  store.loadRouterWork(id);
});


// Extent.
page('extent/:tlx/:tly/:brx/:bry', ctx => {
  const {tlx, tly, brx, bry} = ctx.params;
  const tlbr = [tlx, tly, brx, bry].map(parseFloat);
  store.setRouterExtent(tlbr);
});


// If empty route, show info on startup.
page('', ctx => {
  store.showInfo();
});


export default page;
