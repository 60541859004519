

import React from 'react';
import styled from 'styled-components'

import grey from '@material-ui/core/colors/grey';

import store from '../store';
import {useObservable, useWindowMousePosition} from '../utils';
import {AlphaPaper} from './styles';


const Tip = styled(AlphaPaper)`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
  padding: 5px;
  font-family: Roboto;
  font-size: 0.9em;
  color: ${grey[800]};
  max-width: 300px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.2em;
`;


// TODO: Slow, since we run the React render every tick.
const HighlightTip = () => {

  const work = useObservable(store.HIGHLIGHTED_WORK);
  const visible = useObservable(store.HIGHLIGHT_TIP_VISIBLE);
  const {x, y} = useWindowMousePosition();

  if (!work || !visible) return null;

  const style = {
    top: y + 25,
    left: x - 20,
  }

  return (
    <Tip style={style}>
      <Title>{work.title}</Title>
      <div>{work.authors.join(', ')}</div>
    </Tip>
  )

}


export default HighlightTip;
