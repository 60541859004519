

import axios from 'axios';
import {withPrefix} from 'gatsby';


const API = axios.create({
  baseURL: process.env.API_URL
});


class CanceledRequest {

  constructor() {
    this.prev = null;
  }

  async get(path, params) {

    if (this.prev) {
      this.prev.cancel();
    }

    this.prev = axios.CancelToken.source();

    try {
      return await API.get(path, {params, cancelToken: this.prev.token});
    } catch {}

  }

}


export async function loadTop() {
  return axios.get(withPrefix(`/top.json?${process.env.BUILD_TS}`));
}


const bbRequest = new CanceledRequest();

export async function queryBB(tlbr, size) {
  const path = `/bb/${tlbr.join('/')}`;
  return bbRequest.get(path, {size});
}


const ftRequest = new CanceledRequest();

export async function queryFT(query) {
  return ftRequest.get('/ft', {q: query});
}


export async function loadWork(id) {
  return await API.get(`/work/${id}`);
}


export default API;
