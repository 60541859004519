

import React from 'react';

import store from '../store';
import {useObservable} from '../utils';

import SearchResultsList from './search-results-list';
import SearchResultsSpinner from './search-results-spinner';


const SearchResults = () => {

  const loading = useObservable(store.FT_LOADING, false);
  const visible = useObservable(store.SEARCH_RESULTS_VISIBLE, false);
  const results = useObservable(store.SEARCH_RESULTS);

  if (!visible) return null;

  else if (loading) {
    return <SearchResultsSpinner />
  }

  else if (results) {
    return <QueryMatches results={results} />
  }

  else return <MostFrequent />

}


const QueryMatches = ({results}) => {
  const title = <>{results.total.toLocaleString()} matches</>;
  return <SearchResultsList title={title} works={results.works} />
}


const MostFrequent = () => {

  const topPoints = useObservable(store.TOP_POINTS);

  if (!topPoints) return null;

  const works = topPoints.slice(0, 100);
  const title = <>The {works.length} most frequently-assigned texts</>

  return <SearchResultsList title={title} works={works} />

}


export default SearchResults;
