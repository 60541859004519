

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import {useObservable} from '../utils';
import store from '../store';


const Root = styled.div`
  position: fixed;
  left: 1em;
  bottom: 1em;
  font-family: 'Roboto Mono';
  font-size: 10px;
  font-weight: bold;
  pointer-events: none;
`;

const Label = styled.div`
  margin-top: 0.5rem;
`;


const BBSpinner = () => {

  const loading = useObservable(store.BB_LOADING, false);

  if (!loading) return null;

  return <Root>
    <CircularProgress />
    <Label>Loading points...</Label>
  </Root>

}


export default BBSpinner;
