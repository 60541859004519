

import React, {useState} from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLink} from '@fortawesome/free-solid-svg-icons';


const Root = styled.span`
  cursor: pointer;
  color: ${blue[800]};
  &:hover {
    text-decoration: underline;
  }
`;

const ConfirmSnackbarContent = styled(SnackbarContent)`&& {
  background-color: ${green[600]};
}`;

const ConfirmMessage = styled.div`
  display: flex;
  align-items: center;
`;

const ConfirmMessageIcon = styled(CheckCircleIcon)`
  margin-right: 0.2em;
`;


const CopyLink = ({url, children}) => {

  const [confirmOpen, setConfirmOpen] = useState(false);

  const onClick = () => {
    copy(url);
    setConfirmOpen(true);
  }

  const onConfirmClose = () => setConfirmOpen(false);

  return <>

    <Root onClick={onClick}>
      <FontAwesomeIcon icon={faLink} /> {children}
    </Root>

    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={confirmOpen}
      onClose={onConfirmClose}
      autoHideDuration={3000}
    >
      <ConfirmSnackbarContent
        onClose={onConfirmClose}
        message={<ConfirmMessage>
          <ConfirmMessageIcon />
          Link copied to clipboard!
        </ConfirmMessage>}
      />
    </Snackbar>

  </>

}


export default CopyLink;
