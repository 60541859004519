

import React from 'react';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import {AlphaPaper} from './styles';
import SearchResultsRow from './search-results-row';


const Root = styled(AlphaPaper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  pointer-events: all;
  overflow-y: scroll;
`;

const SearchResultsList = ({title, works}) => {

  const header = (
    <ListItem>
      <ListItemText>
        <Typography variant="subtitle2">
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  )

  const rows = works.map((work) =>
    <SearchResultsRow key={work.work_id} work={work} />)

  return (
    <Root>
      <List dense={true}>{header}{rows}</List>
    </Root>
  )

}


export default SearchResultsList;
